export const isTokenValid = (): boolean => {
    if (typeof window === 'undefined') {
        return;
    }
    if (typeof localStorage.getItem('tokenExpiresAt') === 'undefined') {
        return;
    }

    const expiresAt = parseInt(localStorage.getItem('tokenExpiresAt'));

    return new Date(expiresAt * 1000) > new Date();
};

export const parseToken = (token: string): AuthToken => {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
};

export const setToken = (token: string): AuthToken => {
    if (typeof window === 'undefined') {
        return;
    }
    localStorage.setItem('token', token);
    const data = parseToken(token);
    localStorage.setItem('tokenExpiresAt', data.exp);

    return data;
};

export const getToken = (): string => {
    if (typeof window === 'undefined') {
        return;
    }
    return localStorage.getItem('token');
};

export const getUser = (): AuthUser => {
    const token = localStorage.getItem('token');
    const { email, roles } = parseToken(token);

    return { username: email, roles };
};
