export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gtag: any;
    }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    window.dataLayer?.push({
        event: 'pageview',
        page: url
    });
};

interface EventProps {
    action: string;
    category: string;
    label: string;
    value: string;
}

export const event = ({ action, category, label, value }: EventProps): void => {
    if (typeof window !== 'undefined') {
        window.dataLayer?.push({ action, event_category: category, event_label: label, value });
    }
};
