import type { ThemeConfig } from 'antd';
import { dividerColor, firaSans, primaryColor } from 'styles/GlobalStyle';

export const theme: ThemeConfig = {
    token: {
        motion: false,
        fontFamily: firaSans.style.fontFamily,
        colorPrimary: primaryColor,
        colorLink: primaryColor,
        colorLinkHover: '#222222',
        colorBgMask: 'rgba(0, 0, 0, 0.8)'
    },
    components: {
        Layout: { bodyBg: '#fff', headerBg: '#fff' },
        Divider: {
            colorText: primaryColor,
            colorSplit: dividerColor
        },
        Avatar: { colorBgBase: '#fff' },
        Button: {
            borderRadius: 0,
            borderRadiusLG: 0,
            borderRadiusSM: 0,
            borderRadiusXS: 0
        },
        Input: {
            borderRadius: 0,
            borderRadiusLG: 0,
            borderRadiusSM: 0,
            borderRadiusOuter: 0
        },
        Form: { borderRadius: 0 }
    }
};

export default theme;
