import { applyMiddleware, createStore } from 'redux';
// import { MakeStore, Context } from 'next-redux-wrapper';
import { createWrapper } from 'next-redux-wrapper';

import rootReducer from 'reducer/reducer';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { composeWithDevTools } = require('@redux-devtools/extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

export const makeStore = () => {
    const store = createStore(rootReducer, bindMiddleware([]));

    return store;
};

export const wrapper = createWrapper(makeStore, { debug: false });
