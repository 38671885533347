import produce from 'immer';
import { HYDRATE } from 'next-redux-wrapper';
import {
    FETCH_MENU,
    FETCH_MENU_FAILED,
    FETCH_MENU_SUCCEED,
    TRIGGER_LOGIN_MODAL,
    LOGIN,
    LOGOUT,
    SET_IS_TRANSITIONING
} from './constants';
import menu from '.menu.json';

export interface State {
    menu: MenuItem[];
    loadingMenu: boolean;
    loginModalVisible: boolean;
    isTransitioning: false;
    user?: Person;
    isLoggedIn: boolean;
}

const initialState: State = {
    menu: <MenuItem[]>menu,
    loadingMenu: false,
    loginModalVisible: false,
    isTransitioning: false,
    isLoggedIn: false
};

const reducer = produce((draft, action) => {
    switch (action.type) {
        case HYDRATE:
            draft = action.payload;
            break;
        case FETCH_MENU:
            draft.loadingMenu = true;
            break;
        case FETCH_MENU_SUCCEED:
            draft.menu = action.menu;
            draft.loadingMenu = false;
            break;
        case FETCH_MENU_FAILED:
            draft.loadingMenu = false;
            break;
        case TRIGGER_LOGIN_MODAL:
            draft.loginModalVisible = action.visible;
            break;
        case LOGIN:
            draft.user = action.user;
            draft.isLoggedIn = true;
            break;
        case LOGOUT:
            draft.user = undefined;
            draft.isLoggedIn = false;
            break;
        case SET_IS_TRANSITIONING:
            draft.isTransitioning = action.state;
    }
}, initialState);

export default reducer;
