import {
    FETCH_MENU,
    FETCH_MENU_SUCCEED,
    LOGIN,
    LOGOUT,
    SET_IS_TRANSITIONING,
    TRIGGER_LOGIN_MODAL
} from 'reducer/constants';

interface ActionReturnType {
    type: string;
}

export function fetchMenuAction(): ActionReturnType {
    return {
        type: FETCH_MENU
    };
}

interface FetchMenuSucceedActionReturnType extends ActionReturnType {
    menu: MenuItem[];
}

export function fetchMenuSucceedAction(menu: MenuItem[]): FetchMenuSucceedActionReturnType {
    return {
        type: FETCH_MENU_SUCCEED,
        menu
    };
}

interface TriggerModalReturnType extends ActionReturnType {
    visible: boolean;
}

export function openLoginModal(): TriggerModalReturnType {
    return { type: TRIGGER_LOGIN_MODAL, visible: true };
}

export function closeLoginModal(): TriggerModalReturnType {
    return { type: TRIGGER_LOGIN_MODAL, visible: false };
}

interface LoginReturnType extends ActionReturnType {
    user: AuthUser;
}

export function login(user: AuthUser): LoginReturnType {
    return {
        type: LOGIN,
        user
    };
}

interface TransitioningReturnType extends ActionReturnType {
    state: boolean;
}

export function setIsTransitioning(state: boolean): TransitioningReturnType {
    return {
        type: SET_IS_TRANSITIONING,
        state
    };
}

export function logout(): ActionReturnType {
    return {
        type: LOGOUT
    };
}
